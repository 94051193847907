//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

// Static imports
import ComingSoonTag from '../ReusableComponents/ComingSoonTag.vue';
import CommonFAQSection from '../ReusableComponents/CommonFAQSection.vue';
import SectionHeading from '~/components/ReusableComponents/SectionHeading.vue';
import PrepFooter from '~/components/ReusableComponents/PrepFooter.vue';
import analyticEvents, { ANALYTIC_SOURCE_MODULES } from '~/services/analyticEvents';
import {
    COMING_SOON_SOURCE_MODULES,
    PLATFORM_FEATURES,
    TEST_TYPES,
    TRUSTPILOT_URL
} from '~/static/constants.js';
import { ROUTES } from '~/constants/pageRoutes';
import Icon from '~/components/ReusableComponents/Icon.vue';
import testimonials from '~/static/testimonials';
import TopReviewsBadge from '~/components/ReusableComponents/TopReviewsBadge.vue';
import TestimonialCard from '~/components/ReusableComponents/TestimonialCard.vue';
import { purpleStarIcon } from '~/static/icons';
import { isFeatureAllowed } from '~/services/utilityFunctions';

// Dynamic imports
const HowItWorksWeb = () => import('~/components/Home/HowItWorksWeb.vue');
const HowItWorksPWA = () => import('~/components/Home/HowItWorksPWA.vue');
const InstructionsModal = () => import('./InstructionsModal.vue');
const ModalContainer = () => import('~/components/ReusableComponents/ModalContainer.vue');
const AuthModal = () => import('~/components/Auth/AuthModal.vue');
const GetPlusStickyCta = () => import('~/components/Premium/GetPlusStickyCta.vue');
const PlanExpiryOverlay = () => import('~/components/Premium/PlanExpiryOverlay.vue');
const TrialPeriodHomeScreenPwa = () =>
    import('~/components/OverlayPrompts/TrialPeriodHomeScreenPwa.vue');
const TrialExpiryOverlay = () => import('~/components/OverlayPrompts/TrialExpiryOverlay.vue');
const AppOfferBanner = () => import('~/components/ReusableComponents/AppOfferBanner.vue');
const AppInstallBanner = () => import('~/components/DownloadApp/AppInstallBanner.vue');
const DownloadAppNudgeBanner = () => import('~/components/DownloadApp/DownloadAppNudgeBanner.vue');

const CDN = `${process.env.CDN_ENDPOINT}/testprep/static`;
export default {
    name: 'Home',
    components: {
        SectionHeading,
        PrepFooter,
        HowItWorksWeb,
        HowItWorksPWA,
        InstructionsModal,
        GetPlusStickyCta,
        PlanExpiryOverlay,
        ModalContainer,
        AuthModal,
        TrialPeriodHomeScreenPwa,
        Icon,
        AppOfferBanner,
        TrialExpiryOverlay,
        TestimonialCard,
        TopReviewsBadge,
        AppInstallBanner,
        DownloadAppNudgeBanner,
        ComingSoonTag,
        CommonFAQSection
    },
    data() {
        return {
            // Constants
            ROUTES,
            TEST_TYPES,
            TRUSTPILOT_URL,
            COMING_SOON_SOURCE_MODULES,
            purpleStarIcon,
            yocketPrepLogo: this.$device.isMobile
                ? `${CDN}/icons/yocketPrep_small.svg`
                : `${CDN}/icons/yocketPrep_medium.svg`,
            CDN,
            // Variables
            showPwaStickyNote: false,
            mainFeatures: [
                {
                    id: 1,
                    image: `${CDN}/images/videoLessonFeature.png`,
                    title: 'Video Lessons for Concept Clarity',
                    description:
                        'Learn at your own pace, from the comfort of your home, with topic-based video lessons!'
                },
                {
                    id: 2,
                    image: `${CDN}/images/mockTestFeature.png`,
                    title: 'Full-Length GMAT & GRE Mock Tests',
                    description:
                        'Get the experience of taking GMAT and GRE with 4 mocks that simulate the actual test.'
                },
                {
                    id: 3,
                    image: `${CDN}/images/plannerFeature.png`,
                    title: 'Study Planner to Stay on Schedule',
                    description:
                        'Create a planner based on your test date to stay on track with your prep!'
                },
                {
                    id: 4,
                    image: `${CDN}/images/resourcesFeature.webp`,
                    title: 'Resources to Guide You',
                    description: 'Quench your thirst for knowledge with Expert-curated blogs'
                },
                {
                    id: 5,
                    image: `${CDN}/images/allSubjectTestsFeature.png`,
                    title: 'Section-Based Topic Tests',
                    description: 'Focus on topics that need your attention with curated topic tests'
                },
                {
                    id: 6,
                    image: `${CDN}/images/adaptiveAlgorithmFeature.png`,
                    title: 'Algorithm that Personalizes Test Prep',
                    description:
                        'Our adaptive algorithm learns your capabilities and suggests curated practice to help you improve'
                }
            ],
            secondaryFeatures: [
                {
                    id: 1,
                    icon: `${CDN}/icons/HomeScreen/clipBoardRed.svg`,
                    title: 'Test Score Report ',
                    description:
                        'Get a detailed report of your GMAT and GRE tests with insights on subject-wise performance',
                    backgroundColor: '#FBEAED'
                },
                {
                    id: 2,
                    icon: `${CDN}/icons/HomeScreen/stopCircleYellow.svg`,
                    title: 'Pause and Save',
                    description:
                        'Life happens! You can pause and save your test progress and pick it back up later!',
                    backgroundColor: '#FDF2E0'
                },
                {
                    id: 4,
                    icon: `${CDN}/icons/HomeScreen/timerGreen.svg`,
                    title: 'Timed and Untimed Tests',
                    description:
                        'You can opt between a timed test & untimed test to suit your needs.',
                    backgroundColor: '#F0FDF4'
                },
                {
                    id: 6,
                    icon: `${CDN}/icons/HomeScreen/clipBoardTickPurple.svg`,
                    title: 'Instant Solutions',
                    description:
                        'No need to wait! Get immediate solutions with detailed explanations across all tests.',
                    backgroundColor: '#F5F3FF'
                }
            ],
            howItWorksSteps: [
                {
                    id: 1,
                    heading: 'Take the Performance Analysis Test',
                    body: 'A 30-min test to mark your competence for better GRE® preparation.',
                    image: `${CDN}/images/questions.svg`
                },
                {
                    id: 2,
                    heading: 'Get your Performance Analysis Test Report',
                    body: 'A detailed analysis of your performance to personalise the GRE® practice tests for you.',
                    image: `${CDN}/images/testReport.svg`
                },
                {
                    id: 3,
                    heading: 'Your Quick Prep Dashboard is ready',
                    body: 'Explore the precursor with our ultimate 20+ personalised topics and free GRE® mock tests.',
                    image: `${CDN}/images/dashboard.svg`
                }
            ],
            faqs: [
                {
                    order: 1,
                    title: 'Is Yocket Prep a good test prep option?',
                    description:
                        'Yes, Yocket Prep is a good option for the GRE and GMAT test prep. It offers personalised study plans, expert-led virtual classes, AI-driven tools, and practice tests.'
                },
                {
                    order: 2,
                    title: 'How much does Yocket Prep cost?',
                    description: `Yocket Prep offers various pricing options depending on the package you choose. It can range from INR 5,999 to INR 24,999 based on whether you choose GRE or GMAT test prep. For the most accurate and up-to-date information on pricing, it's best to visit the Yocket website or contact their support team.`
                },
                {
                    order: 3,
                    title: 'How is Yocket Prep for the GMAT and GRE?',
                    description:
                        'Yocket Prep is excellent for thorough preparation and is also considered among the best GMAT and GRE test prep. With detailed lessons covering all sections, practice tests, study schedules, and real-time feedback, it offers students with the strategies and knowledge they need to succeed on either exam.'
                },
                {
                    order: 4,
                    title: 'How do I access Yocket Prep practice tests?',
                    description: `You can access Yocket Prep practice tests directly through the Yocket Prep platform once you've signed up. The tests are available as part of the study resources in your personalised dashboard.`
                },
                {
                    order: 5,
                    title: 'Are Yocket Prep practice tests harder?',
                    description: `Yocket Prep practice tests are designed to closely simulate the actual GMAT and GRE exams. They may feel challenging, but they are created to prepare you effectively for the real test, ensuring you're ready for any difficulty level.`
                },
                {
                    order: 6,
                    title: 'Is Yocket Prep worth it?',
                    description:
                        'Yes, Yocket Prep is worth it for students who want personalised, flexible, and 100% preparation. The AI-driven platform, expert instruction, and wide range of resources make it a valuable investment for those aiming for high scores.'
                },
                {
                    order: 7,
                    title: 'What is the best test prep?',
                    description: `The best test prep depends on your learning style and goals. Yocket Prep stands out due to its unique approach, tailored study plans, and a range of tools that adapt to your progress. It's a top choice for GRE and GMAT aspirants.`
                },
                {
                    order: 8,
                    title: 'How do I prepare for a test online?',
                    description:
                        'To prepare for a test online, choose a reliable platform like Yocket Prep which offers virtual classes, recorded lessons, practice tests, and AI-driven tools.'
                }
            ],
            isLoginModalOpen: false,
            signUpType: analyticEvents.signup_type.home,
            testimonials: testimonials.home
        };
    },
    computed: {
        ...mapGetters({
            hasGivenQuickDiagnostic: 'auth/hasGivenQuickDiagnostic',
            hasGivenFullDiagnostic: 'auth/hasGivenFullDiagnostic',
            recentQuickDiagId: 'auth/recentQuickDiagId',
            recentFullDiagId: 'auth/recentFullDiagId',
            showInstructions: 'testSets/showInstructions',
            isAuthenticated: 'auth/isAuthenticated',
            user: 'auth/getUser',
            isPremiumUser: 'auth/isPremiumUser',
            quickDiagnosticData: 'testSets/quickDiagnosticData',
            showPlanExpiryOverlay: 'auth/showPlanExpiryOverlay',
            freeTrialStatus: 'auth/freeTrialStatus',
            featurePermissions: 'auth/featurePermissions'
        }),
        showPwaBanner() {
            // if user is logged out or has taken FD => never show
            if (
                !this.$device.isMobileOrTablet ||
                !this.isAuthenticated ||
                this.hasGivenFullDiagnostic
            )
                return false;

            // ------- User will always be logged in and in QD stage here onwards ---------

            // if Default Dashboard is set => always show
            if (!this.hasGivenQuickDiagnostic) return true;

            // if trial expires => always show
            if (
                !this.isPremiumUser &&
                this.freeTrialStatus.activated &&
                this.freeTrialStatus.expired
            )
                return true;

            /**
             * Cases to reach here -
             * 1. user has taken QD themselves + trial is valid.
             * 2. user has taken QD themselves + premium user.
             */
            return false;
        }
    },
    async mounted() {
        if (!isFeatureAllowed(PLATFORM_FEATURES.DASHBOARD, this.featurePermissions)) return;
        // If QD data not already present in store then fetch
        if (!this.quickDiagnosticData)
            await this.$store.dispatch('testSets/fetchDiagnosticTestData', 'QD');

        // Handle CTA clicks on yocket.com/prep-reviews
        setTimeout(() => {
            if (this.$route.query?.action === 'start-test') {
                this.startTest(this.$route.query?.source || 'wall-of-fame');
            }
            if (this.$route.query?.action === 'start-trial') {
                this.goToDashboard();
            }
        }, 500);
    },
    methods: {
        async startTest(source) {
            if (!this.quickDiagnosticData) {
                // If the QD data is not available, try to fetch again and contiue, if it still fails then return
                const response = await this.$store.dispatch(
                    'testSets/fetchDiagnosticTestData',
                    'QD'
                );

                if (!response.state) return;
            }

            // Set the instructions for the test to begin that is QD
            this.$store.commit('testSets/setActiveTest', this.quickDiagnosticData);
            this.$store.commit('testSets/setShowInstructions', true);

            let trackingEvent = {};
            switch (source) {
                case 'landing_page_main_cta': {
                    trackingEvent = {
                        event: analyticEvents.gre_diagnostic_test,
                        data: {
                            source: ANALYTIC_SOURCE_MODULES.HOMEPAGE
                        }
                    };
                    break;
                }
                case 'landing_page_bottom_cta': {
                    trackingEvent = {
                        event: analyticEvents.start_gre_test,
                        data: {
                            source: ANALYTIC_SOURCE_MODULES.HOMEPAGE
                        }
                    };
                    break;
                }
                default: {
                    trackingEvent = {
                        event: analyticEvents.quickdiag_start_test,
                        data: {
                            source
                        }
                    };
                }
            }
            this.$store.dispatch('analytics/trackEvent', trackingEvent);
        },
        handleAnalyticEvent() {
            if (!this.isAuthenticated && !this.hasGivenQuickDiagnostic) {
                this.$store.dispatch('analytics/trackEvent', {
                    event: analyticEvents.start_free_trial,
                    data: {
                        source: ANALYTIC_SOURCE_MODULES.HOMEPAGE
                    }
                });
            }
        },
        goToDashboard() {
            if (!this.isAuthenticated) {
                if (this.$device.isMobileOrTablet) {
                    this.$store.commit('auth/setContinueRoute', this.$route.path);
                    this.$router.push({
                        path: ROUTES.LOGIN,
                        query: { tab: 'signup' }
                    });
                } else this.isLoginModalOpen = !this.isLoginModalOpen;

                const trackingEvent = {
                    event: analyticEvents.startnow_signup_begin
                };
                this.$store.dispatch('analytics/trackEvent', trackingEvent);

                return;
            }

            // Mobile device + logged in + QD or FD taken => view report
            if (
                this.$device.isMobile &&
                (this.hasGivenQuickDiagnostic || this.hasGivenFullDiagnostic)
            ) {
                // initialize with QD but if the user has already taken the FD then overwrite that
                let testTakerId = this.recentQuickDiagId;
                if (this.hasGivenFullDiagnostic) testTakerId = this.recentFullDiagId;

                this.$store.commit('questions/setCanChangeRoute', true);
                this.$router.push({
                    path: ROUTES.PERFORMANCE,
                    query: { ttid: testTakerId }
                });
                return;
            }

            this.$router.push(ROUTES.DASHBOARD);
        },
        registerObserver() {
            const reference = this.$refs.pwaMessage;

            const observer = new IntersectionObserver(
                (entries) => {
                    if (!this.scrolling) {
                        const elem = entries[0];

                        this.showPwaStickyNote =
                            elem.boundingClientRect.y < 0 && !elem.isIntersecting;
                    }
                },
                { threshold: [0.0], rootMargin: '-64px 0px 0px 0px' }
            );
            observer.observe(reference.$el || reference);
        },
        featureSectionCtaHandler() {
            this.$router.push(ROUTES.PRICING);
        },
        redirectToTrustpilotPage() {
            window.open(TRUSTPILOT_URL, '_blank');

            const trackingEvent = {
                event: analyticEvents.prep_morereviews_lp
            };
            this.$store.dispatch('analytics/trackEvent', trackingEvent);
        }
    }
};
